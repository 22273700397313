.MuiInputBase-root {
    background-color: white;
}

.MuiFilledInput-root:hover {
    background-color: white;
}

.MuiFormControl-root {
    border-bottom-color: #0F3F62;
}

.MuiFormControlLabel-root{
    margin: 0;
}

.MuiIconButton-label {
    background-color: rgba(0,0,0,0) !important;
    padding : 0;
}

.MuiButton-containedPrimary {
    color : white;
    background-color : #55BDBC;
}

.MuiIconButton-colorSecondary:hover {
    background-color : #55BDBC20 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color : #55BDBC !important;
}