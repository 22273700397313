@font-face {
    font-family: 'futura-medium-bt', sans-serif;
    src: url('../webfonts/futura-medium-bt.ttf');
}

h1{
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 4em;
    line-height: 60pt;
}
h2{
    font-family: 'Montserrat', sans-serif;
    color: #646464;
}
h3.subtitle{
    font-family: 'Montserrat', sans-serif;
    font-size: 2em;
    line-height: 47pt;
    color: #646464;
}

.mt-10{
    margin-top: 10rem;
}
.pt-10{
    padding-top: 10rem;
}
.pt-7{
    padding-top: 7rem;
}
.mx-inherit{
    margin-right: inherit;
    margin-left: inherit;
}
.pt-1dot5{
    padding-top: 1.5rem;
}
.mt-2rem{
    margin-top: 2rem;
}
.mr-4rem{
    margin-right: 4rem;
}

.vh-100{
    height: 100vh;
}
.vh-20{
    height: 15vh;
}
.w-35{
    width: 35%;
}

.input-search{
    border: 1px solid rgba(255,255,255,0.4);
    background-clip: padding-box;
    color: black;
    padding-top: 1.35rem;
    padding-bottom: 1.35rem;
}
.white-skin input[type="text"]:focus:not([readonly]), .white-skin input[type="password"]:focus:not([readonly]), .white-skin input[type="number"]:focus:not([readonly]), .white-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: inherit;
    box-shadow: inherit;
    color: black;
}
.search-header{
    font-size: 25px;
    font-family: 'futura-medium-bt', sans-serif;
    margin-bottom: 0.1rem;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #f7fafc;
}
::-webkit-scrollbar {
	width: 12px;
	background-color: #f7fafc;
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #0f3f62;
}

input[type="text"].form-control{
    background-clip: padding-box;
    color: black;
}

.md-form.md-outline input[type="text"]{
    border-radius: 0.5rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}
.md-form.md-outline input[type="text"] label{
    padding-left: 15px;
}

@media (min-width: 576px){
    .modal-dialog {
        max-width: 800px;   
    }
}

.z-depth-0dot5{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 6px 0 rgba(0, 0, 0, 0.12) !important;
}

.ta-info-step > label + .MuiInput-formControl {
    margin-top: 26px;
}

#calculator-form-dialog > .MuiTextField-root > .MuiInput-root {
    margin-top: 25px!important;
}