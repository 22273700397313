@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  color: #0F3F62;
  background-color: #F7FAFC;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-action > div > button{
  font-size: 1.1rem!important;
}

.badge-success {
  color: #0e6245 !important;
  background-color: #cbf4c9 !important;
}
.badge-warning {
  color: #983705 !important;
  background-color: #F8E5D0 !important;
}

.MuiButton-textPrimary{
  background-color: #5469d4!important;
  color: white!important;
}
.MuiButton-textPrimary.Mui-disabled{
  background-color: rgba(84, 105, 212, 0.26) !important;
  color: white!important;
}

.MuiButton-textSecondary{
  color: #E02020!important;
}
.MuiButton-text.badge-success.Mui-disabled{
  color: #0e6245 !important;
  background-color: rgba(203, 244, 201, 0.26) !important;
}
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
    color: #55BDBC;
    background-color: #55BDBC19 !important;
}

.MuiListItem-root.Mui-selected > div > i, .MuiListItem-root.Mui-selected:hover > div > i{
    color: #55BDBC;
}

.MuiListItem-button:hover {
    color: #55BDBC;
    background-color: #55BDBC19 !important;
}

.MuiIconButton-label {
    color: #55BDBC;
    background-color: #55BDBC19 !important;
}

.marquee {
    width: 90%;
    margin-left: 5%;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}
.marquee p {
    display: inline-block;
    padding-left: 0px;
    -webkit-animation: marquee 10s linear infinite;
            animation: marquee 10s linear infinite;
}
@-webkit-keyframes marquee {
    0% { transform: translate(0%, 0); }
    100% { transform: translate(-100%, 0); }
}
@keyframes marquee {
    0% { transform: translate(0%, 0); }
    100% { transform: translate(-100%, 0); }
}
/*
@keyframes marquee {
    0%   { transform: translate(-50%, 0); }
    50%   { transform: translate(-100%, 0); }
    100% { transform: translate(-100%, 0); }
}
*/
table.table th, table.table td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.dashboard .table td{
    border-top: none;
    vertical-align: middle;
    font-weight: bolder;
    color: #0F3F62;
}
.dashboard .table td > div > p{
    border-top: none;
    vertical-align: middle;
    font-weight: bolder;
    color: #0F3F62;
    font-size: 0.95rem;
}

.dashboard .validate{
    background-color: #ffffff !important;
}
.dashboard .table tr{
    background-color: #ffffff;
    border-bottom: 1px solid #D6DCE0;
    line-height: 3;
    color: #525c6e;
}

.dashboard .table tr td:first-child {
    padding-left: 2vw;
}
.table-head-bold > thead > tr > th{
    font-weight: bold;
}

.tr-hover:hover{
    background-color:  rgba(41, 121, 242, 0.1)!important;
}
.tr-hover{
    cursor: pointer!important;
}

.dashboard-content{
    border-radius: 30px;
}
.dashboard-content>div.animated{
    padding: 1rem;
}

.dashboard-header{
    padding-top: 3rem;
}

.view-content{
    padding-top: 3rem;
}

.dashboard > .md-form label {
    color: #0F3F62;
}
.md-form label.active {
    color: black !important;
}

.signin > .md-form label {
    color: white !important;
}

.md-form i.active {
    color: black !important;
}

.md-form input:focus {
    border-color: black !important;
    box-shadow: inset 0 0 0 0 black !important;
    border-bottom: 1px solid black !important;
}


@font-face {
    font-family: 'futura-medium-bt', sans-serif;
    src: url(/static/media/futura-medium-bt.31d6cfe0.ttf);
}

h1{
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 4em;
    line-height: 60pt;
}
h2{
    font-family: 'Montserrat', sans-serif;
    color: #646464;
}
h3.subtitle{
    font-family: 'Montserrat', sans-serif;
    font-size: 2em;
    line-height: 47pt;
    color: #646464;
}

.mt-10{
    margin-top: 10rem;
}
.pt-10{
    padding-top: 10rem;
}
.pt-7{
    padding-top: 7rem;
}
.mx-inherit{
    margin-right: inherit;
    margin-left: inherit;
}
.pt-1dot5{
    padding-top: 1.5rem;
}
.mt-2rem{
    margin-top: 2rem;
}
.mr-4rem{
    margin-right: 4rem;
}

.vh-100{
    height: 100vh;
}
.vh-20{
    height: 15vh;
}
.w-35{
    width: 35%;
}

.input-search{
    border: 1px solid rgba(255,255,255,0.4);
    background-clip: padding-box;
    color: black;
    padding-top: 1.35rem;
    padding-bottom: 1.35rem;
}
.white-skin input[type="text"]:focus:not([readonly]), .white-skin input[type="password"]:focus:not([readonly]), .white-skin input[type="number"]:focus:not([readonly]), .white-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: inherit;
    box-shadow: inherit;
    color: black;
}
.search-header{
    font-size: 25px;
    font-family: 'futura-medium-bt', sans-serif;
    margin-bottom: 0.1rem;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #f7fafc;
}
::-webkit-scrollbar {
	width: 12px;
	background-color: #f7fafc;
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #0f3f62;
}

input[type="text"].form-control{
    background-clip: padding-box;
    color: black;
}

.md-form.md-outline input[type="text"]{
    border-radius: 0.5rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}
.md-form.md-outline input[type="text"] label{
    padding-left: 15px;
}

@media (min-width: 576px){
    .modal-dialog {
        max-width: 800px;   
    }
}

.z-depth-0dot5{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 6px 0 rgba(0, 0, 0, 0.12) !important;
}

.ta-info-step > label + .MuiInput-formControl {
    margin-top: 26px;
}

#calculator-form-dialog > .MuiTextField-root > .MuiInput-root {
    margin-top: 25px!important;
}
.MuiInputBase-root {
    background-color: white;
}

.MuiFilledInput-root:hover {
    background-color: white;
}

.MuiFormControl-root {
    border-bottom-color: #0F3F62;
}

.MuiFormControlLabel-root{
    margin: 0;
}

.MuiIconButton-label {
    background-color: rgba(0,0,0,0) !important;
    padding : 0;
}

.MuiButton-containedPrimary {
    color : white;
    background-color : #55BDBC;
}

.MuiIconButton-colorSecondary:hover {
    background-color : #55BDBC20 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color : #55BDBC !important;
}
