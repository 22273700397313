.md-form label.active {
    color: black !important;
}

.signin > .md-form label {
    color: white !important;
}

.md-form i.active {
    color: black !important;
}

.md-form input:focus {
    border-color: black !important;
    box-shadow: inset 0 0 0 0 black !important;
    border-bottom: 1px solid black !important;
}

