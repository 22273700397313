table.table th, table.table td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.dashboard .table td{
    border-top: none;
    vertical-align: middle;
    font-weight: bolder;
    color: #0F3F62;
}
.dashboard .table td > div > p{
    border-top: none;
    vertical-align: middle;
    font-weight: bolder;
    color: #0F3F62;
    font-size: 0.95rem;
}

.dashboard .validate{
    background-color: #ffffff !important;
}
.dashboard .table tr{
    background-color: #ffffff;
    border-bottom: 1px solid #D6DCE0;
    line-height: 3;
    color: #525c6e;
}

.dashboard .table tr td:first-child {
    padding-left: 2vw;
}
.table-head-bold > thead > tr > th{
    font-weight: bold;
}

.tr-hover:hover{
    background-color:  rgba(41, 121, 242, 0.1)!important;
}
.tr-hover{
    cursor: pointer!important;
}

.dashboard-content{
    border-radius: 30px;
}
.dashboard-content>div.animated{
    padding: 1rem;
}

.dashboard-header{
    padding-top: 3rem;
}

.view-content{
    padding-top: 3rem;
}

.dashboard > .md-form label {
    color: #0F3F62;
}