.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
    color: #55BDBC;
    background-color: #55BDBC19 !important;
}

.MuiListItem-root.Mui-selected > div > i, .MuiListItem-root.Mui-selected:hover > div > i{
    color: #55BDBC;
}

.MuiListItem-button:hover {
    color: #55BDBC;
    background-color: #55BDBC19 !important;
}

.MuiIconButton-label {
    color: #55BDBC;
    background-color: #55BDBC19 !important;
}

.marquee {
    width: 90%;
    margin-left: 5%;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}
.marquee p {
    display: inline-block;
    padding-left: 0px;
    animation: marquee 10s linear infinite;
}
@keyframes marquee {
    0% { transform: translate(0%, 0); }
    100% { transform: translate(-100%, 0); }
}
/*
@keyframes marquee {
    0%   { transform: translate(-50%, 0); }
    50%   { transform: translate(-100%, 0); }
    100% { transform: translate(-100%, 0); }
}
*/